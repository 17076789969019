<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<section class="content">
	<ol class="breadcrumb">
		<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
			[class.active]="i===breadcrumbList.length-1">
			<a [routerLink]="item.path" id="breadcrumbLink{{i}}" *ngIf="i!==breadcrumbList.length-1">
				<b>{{ item.name }}</b>
			</a>
			<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
		</li>
	</ol>

	<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div class="panel-body">
					<div>
						<form class="form-horizontal" [formGroup]="eventForm" (ngSubmit)="saveEvent()"
							#eventform="ngForm">
							<div class="panel panel-info" style="padding-bottom: 10px;">
								<div class="panel-heading">
									Vessel Information
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">IMO No.:</label>
										<input type="text" name="imoNo" id="imoNo" formControlName="imoNo"
											placeholder="IMO No." class="form-control" (keyup)="imoKeyup();" />
										<small
											*ngIf="f.imoNo.invalid && (eventform.submitted || f.imoNo.touched || f.imoNo.dirty)"
											style="color: maroon;">
											<span *ngIf="f.imoNo.errors?.incorrect">
												{{customError.imoNo}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label"><span
												style="color: #b40a09;">*</span>Vessel:</label>
										<input type="text" name="vesselName" id="vesselName"
											formControlName="vesselName" placeholder="Vessel Name"
											class="form-control" />
										<small
											*ngIf="f.vesselName.invalid && ( eventform.submitted || f.vesselName.touched || f.vesselName.dirty)"
											style="color: maroon;">
											<span *ngIf="f.vesselName.errors?.required">
												Please enter something.
											</span>
											<span *ngIf="f.vesselName.errors?.incorrect">
												{{customError.vesselName}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Call Sign:</label>
										<input type="text" name="callSign" id="callSign" formControlName="callSign"
											placeholder="Call Sign" class="form-control" />
										<small
											*ngIf="f.callSign.invalid && ( eventform.submitted || f.callSign.touched || f.callSign.dirty)"
											style="color: maroon;">
											<span *ngIf="f.callSign.errors?.incorrect">
												{{customError.callSign}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Next Port:</label>
										<input type="text" id="arrivalPort" placeholder="Next Port" class="form-control"
											matInput formControlName="arrivalPort" [matAutocomplete]="auto">
										<mat-autocomplete #auto="matAutocomplete" [displayWith]="getPortText" id="auto">
											<mat-option *ngFor="let option of filteredPortList | async"
												[value]="option">
												{{option.portName}}
											</mat-option>
										</mat-autocomplete>
										<small
											*ngIf="f.arrivalPort.invalid && (eventform.submitted || f.arrivalPort.touched || f.arrivalPort.dirty)"
											style="color: maroon;">
											<span *ngIf="f.arrivalPort.errors?.incorrect">
												{{customError.arrivalPort}}
											</span>
										</small>
									</div>
									<!-- <div class="col-lg-3 col-sm-3">
										<label class="control-label">Captain Name:</label>
										<input type="text" name="captainName" id="captainName"
											formControlName="captainName" placeholder="Captain Name"
											class="form-control" />
										<small
											*ngIf="f.captainName.invalid && (eventform.submitted || f.captainName.touched || f.captainName.dirty)"
											style="color: maroon;">
											<span *ngIf="f.captainName.errors.pattern">
												Not valid Name
											</span>
											<span *ngIf="f.captainName.errors?.incorrect">
												{{customError.captainName}}
											</span>
										</small>
									</div> -->
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">ETA Next Port:</label>

										<div class="mat-datepicker-container">
											<input id="etaNextPort" [matDatepicker]="etaNextPortPicker"
												(click)="etaNextPortPicker.open()" formControlName="etaNextPort"
												readonly class="form-control" matInput>
											<mat-datepicker-toggle matSuffix
												[for]="etaNextPortPicker"></mat-datepicker-toggle>
										</div>
										<mat-datepicker #etaNextPortPicker></mat-datepicker>
										<small
											*ngIf="f.etaNextPort.invalid && (eventform.submitted || f.etaNextPort.touched || f.etaNextPort.dirty)"
											style="color: maroon;">
											<span *ngIf="f.etaNextPort.errors?.incorrect">
												{{customError.etaNextPort}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Closest Port:</label>
										<input type="text" id="closestPort" placeholder="Closest Port"
											class="form-control" matInput formControlName="closestPort"
											[matAutocomplete]="autoClosest">
										<mat-autocomplete #autoClosest="matAutocomplete"
											[displayWith]="getPortTextClosest" id="autoClosest">
											<mat-option *ngFor="let option of filteredPortListClosest | async"
												[value]="option">
												{{option.portName}}
											</mat-option>
										</mat-autocomplete>
										<small
											*ngIf="f.closestPort.invalid && (eventform.submitted || f.closestPort.touched || f.closestPort.dirty)"
											style="color: maroon;">
											<span *ngIf="f.closestPort.errors?.incorrect">
												{{customError.closestPort}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Hours to Reach to Closest Port:</label>
										<input type="number" id="daystoClosestPort" name="daystoClosestPort"
											formControlName="daystoClosestPort"
											placeholder="Hours to Reach to Closest Port" class="form-control" />
										<small
											*ngIf="f.daystoClosestPort.invalid && (eventform.submitted || f.daystoClosestPort.touched || f.daystoClosestPort.dirty)"
											style="color: maroon;">
											<span *ngIf="f.daystoClosestPort.errors?.incorrect">
												{{customError.daystoClosestPort}}
											</span>
										</small>
									</div>
								</div>
								<!-- <div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Lattitude:</label>
										<input type="text" id="lattitude" name="lattitude" formControlName="lattitude"
											placeholder="Lattitude" class="form-control" />
											<small
											*ngIf="f.lattitude.invalid && (eventform.submitted || f.lattitude.touched || f.lattitude.dirty)"
											style="color: maroon;">
											<span *ngIf="f.lattitude.errors?.incorrect">
												{{customError.lattitude}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Longitude:</label>
										<input type="text" id="longitude" name="longitude" formControlName="longitude"
											placeholder="Longitude" class="form-control" />
											<small
											*ngIf="f.longitude.invalid && (eventform.submitted || f.longitude.touched || f.longitude.dirty)"
											style="color: maroon;">
											<span *ngIf="f.longitude.errors?.incorrect">
												{{customError.longitude}}
											</span>
										</small>
									</div>
								</div> -->
							</div>

							<div class="panel panel-info" style="padding-bottom: 10px;">
								<div class="panel-heading">
									Candidate Information
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">3C ID:</label>
										<input type="text" id="candidate_ccid" name="candidate_ccid"
											formControlName="candidate_ccid" placeholder="3C ID" class="form-control"
											(keyup)="cIdKeyup();" />
										<small
											*ngIf="f.candidate_ccid.invalid && (eventform.submitted || f.candidate_ccid.touched || f.candidate_ccid.dirty)"
											style="color: maroon;">
											<span *ngIf="f.candidate_ccid.errors?.notvalid">
												Please enter valid Candidate 3C Id
											</span>
											<span *ngIf="f.candidate_ccid.errors?.incorrect">
												{{customError.candidate_ccid}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label"><span style="color: #b40a09;">*</span>First
											Name:</label>
										<input type="text" id="firstName" name="firstName" formControlName="firstName"
											placeholder="First Name" class="form-control" />
										<small
											*ngIf="f.firstName.invalid && (eventform.submitted || f.firstName.touched || f.firstName.dirty)"
											style="color: maroon;">
											<span *ngIf="f.firstName.errors?.required">
												Please enter something.
											</span>
											<span *ngIf="f.firstName.errors?.incorrect">
												{{customError.firstName}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label"><span style="color: #b40a09;">*</span>Last
											Name:</label>
										<input type="text" id="lastName" name="lastName" formControlName="lastName"
											placeholder="Last Name" class="form-control" />
										<small
											*ngIf="f.lastName.invalid && (eventform.submitted || f.lastName.touched || f.lastName.dirty)"
											style="color: maroon;">
											<span *ngIf="f.lastName.errors?.required">
												Please enter something.
											</span>
											<span *ngIf="f.lastName.errors?.incorrect">
												{{customError.lastName}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Date Of Birth:</label>
										<div class="mat-datepicker-container">
											<input id="dateOfBirth" [matDatepicker]="dobPicker" [max]="maxDate"
												(click)="dobPicker.open()" formControlName="dateOfBirth" readonly
												class="form-control" matInput>
											<mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
										</div>
										<mat-datepicker #dobPicker
											[disabled]="candidate_disabled ? '' : null"></mat-datepicker>
										<small
											*ngIf="f.dateOfBirth.invalid && (eventform.submitted || f.dateOfBirth.touched || f.dateOfBirth.dirty)"
											style="color: maroon;">
											<span *ngIf="f.dateOfBirth.errors?.required">
												Please enter something.
											</span>
											<span *ngIf="f.dateOfBirth.errors?.incorrect">
												{{customError.dateOfBirth}}
											</span>
											<span *ngIf="f.dateOfBirth.errors?.matDatepickerMax">
												Invalid Date
											</span>
										</small>
									</div>
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label class="control-label"><span
												style="color: #b40a09;">*</span>Gender:</label><br>
										<mat-radio-group formControlName="gender">
											<mat-radio-button id="radioMale" color="primary"
												value="Male">Male</mat-radio-button>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<mat-radio-button id="radioFemale" color="primary"
												value="Female">Female</mat-radio-button>
										</mat-radio-group><br>
										<small
											*ngIf="f.gender.invalid && (eventform.submitted || f.gender.touched || f.gender.dirty)"
											style="color: maroon;">
											<span *ngIf="f.gender.errors?.required">
												Please select gender.
											</span>
											<span *ngIf="f.gender.errors?.incorrect">
												{{customError.gender}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Days On Board:</label>
										<input type="text" id="dayDifference" name="dayDifference"
											formControlName="dayDifference" class="form-control" />
										<small
											*ngIf="f.dayDifference.invalid && (eventform.submitted || f.dayDifference.touched || f.dayDifference.dirty)"
											style="color: maroon;">
											<span *ngIf="f.dayDifference.errors?.incorrect">
												{{customError.dayDifference}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Rank:</label>
										<ng-select [items]="ranklist" id="rank" bindLabel="rank" bindValue="id"
											[clearable]="true" placeholder="Select Field" name="rank"
											formControlName="rank" [readonly]="rank_disabled">
											<ng-template ng-label-tmp let-item="item">
												{{item.rank}}
											</ng-template>
											<ng-template ng-option-tmp let-item="item" let-search="searchTerm"
												let-index="index">
												{{item.rank}}
											</ng-template>
										</ng-select>
										<small
											*ngIf="f.rank.invalid && (eventform.submitted || f.rank.touched || f.rank.dirty)"
											style="color: maroon;">
											<span *ngIf="f.rank.errors?.incorrect">
												{{customError.rank}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Employee Id:</label>
										<input type="text" id="emp_id" name="emp_id" formControlName="emp_id"
											placeholder="Card Number" class="form-control" />
										<small
											*ngIf="f.emp_id.invalid && (eventform.submitted || f.emp_id.touched || f.emp_id.dirty)"
											style="color: maroon;">
											<span *ngIf="f.emp_id.errors?.pattern">
												Please enter valid employee id.
											</span>
											<span *ngIf="f.emp_id.errors?.incorrect">
												{{customError.emp_id}}
											</span>
										</small>
									</div>
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">
											<span style="color: #b40a09;">*</span>
											Nationality:
										</label>
										<!-- <input type="text" placeholder="Nationality" class="form-control" matInput
											id="nationality" formControlName="nationality"
											[matAutocomplete]="nationalityautocom"
											[attr.disabled]="nationality_disabled ? '' : null">
										<mat-autocomplete #nationalityautocom="matAutocomplete"
											[displayWith]="getNationalityText" id="nationalityautocom">
											<mat-option *ngFor="let option of filteredNationality | async"
												[value]="option">
												{{option.nationality}}
											</mat-option>
										</mat-autocomplete> -->
										<input type="text" id="nationality" placeholder="Nationality"
											class="form-control" matInput formControlName="nationality"
											[matAutocomplete]="auto1">
										<mat-autocomplete #auto1="matAutocomplete" [displayWith]="getNationalityText"
											id="auto1">
											<mat-option *ngIf="isLoading"><i
													class="fa fa-spinner fa-spin"></i>Loading...</mat-option>
											<ng-container *ngIf="!isLoading">
												<mat-option *ngFor="let option of filteredNationality" [value]="option">
													{{option.nationality}}
												</mat-option>
											</ng-container>
										</mat-autocomplete>
										<small
											*ngIf="f.nationality.invalid && (eventform.submitted || f.nationality.touched || f.nationality.dirty)"
											style="color: maroon;">
											<span *ngIf="f.nationality.errors?.required">
												Required
											</span>
											<span *ngIf="f.nationality.errors?.incorrect">
												{{customError.nationality}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Email Id:</label>
										<input type="email" id="email" name="email" formControlName="email"
											placeholder="Email Id" class="form-control" />
										<small *ngIf="f.email.invalid && (eventform.submitted ||  f.email.dirty)"
											style="color: maroon;">
											<span *ngIf="f.email.errors?.pattern">
												Please enter valid email!
											</span>
											<span *ngIf="f.email.errors?.incorrect">
												{{customError.email}}
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="col-sm-12 pl0 pr0">Number :</label>
										<div class="col-sm-4 pl0 pr0">
											<input type="text" id="code" name="code" formControlName="code"
												placeholder="+91" class="form-control" />
											<small *ngIf="f.code.invalid && (eventform.submitted ||  f.code.dirty)"
												style="color: maroon;">
												<span *ngIf="f.code.errors?.pattern">
													Please enter valid code!
												</span>
												<span *ngIf="f.code.errors?.incorrect">
													{{customError.code}}
												</span>
											</small>
										</div>
										<div class="col-sm-8 pl0 pr0">
											<input type="text" id="telephone" name="telephone"
												formControlName="telephone" placeholder="Number" class="form-control" />
											<small
												*ngIf="f.telephone.invalid && (eventform.submitted ||  f.telephone.dirty)"
												style="color: maroon;">
												<span *ngIf="f.telephone.errors?.pattern">
													Please enter valid telephone!
												</span>
												<span *ngIf="f.telephone.errors?.minlength">
													Please enter min 6 characters!
												</span>
												<span *ngIf="f.telephone.errors?.maxlength">
													Please enter max 10 characters!
												</span>
												<span *ngIf="f.telephone.errors?.incorrect">
													{{customError.telephone}}
												</span>
											</small>
										</div>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label class="control-label">Address :</label>
										<input type="text" id="address" name="address" formControlName="address"
											placeholder="Address" class="form-control" />
										<small *ngIf="f.address.invalid && (eventform.submitted ||  f.address.dirty)"
											style="color: maroon;">
											<span *ngIf="f.address.errors?.incorrect">
												{{customError.address}}
											</span>
										</small>
									</div>
								</div>
							</div>
							<div class="panel panel-info" style="padding-bottom: 10px;">
								<div class="panel-heading">
									SMR (Smart Medical Request)
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-sm-12">
										<div class="panel">
											<div class="panel-body">
												<div style="font-size: large !important;margin-top: 10px;margin-bottom: 10px;"
													class="app-view-header">
													<button title="Reset" *ngIf="!isObjectEmpty()"
														(click)="resetGroupMasters()" type="button"
														class="pull-right btn btn-sm btn-warning mr"
														id="btnReset">Reset</button>
													<button type="button" *ngIf="!isObjectEmpty()"
														[disabled]="!loadNext" (click)="convertObjToArrayGroups()"
														class="pull-right btn btn-sm btn-success mr"
														id="btnNext">Next</button>
												</div>
												<hr
													style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
												<div class="form-group">
													<div [ngClass]="class412">
														<label class="col-sm-12">
															<span style="color: #b40a09;">*</span>Select Symptom :
														</label>
														<ng-container *ngFor="let data of groupList | orderBy:'label'">
															<div *ngIf="!data.hideCheckBox" class="col-sm-6">
																<div class="checkbox c-checkbox">
																	<label>
																		<input type="checkbox" id="check{{data.id}}"
																			value="data.id"
																			[(ngModel)]="selectedMeds[data.id]"
																			[ngModelOptions]="{standalone: true}"
																			(change)="watchSelectedMeds();unselectOthers(data.id);"
																			[disabled]="disablecheckboxes" />
																		<span class="fa fa-check"
																			style="border-radius: 10px;"></span>{{data.label}}
																	</label>
																</div>
															</div>
														</ng-container>
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="questionaaire">
											<div class="col-sm-12">
												<div class="panel">
													<div class="panel-body">
														<img src="../assets/img/loading.gif"
															style="height: 200px;position: absolute;left: 50%;z-index: 1"
															*ngIf="LoadingGIF" />
														<div class="col-sm-12">
															<!-- <div style="font-size: large !important;margin-top: 10px;margin-bottom: 10px;"
																class="app-view-header">
																<span>What To Do ?</span>
															</div>
															<hr style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" /> -->
															<div *ngFor="let data of questionsList" class="col-sm-12">
																<div *ngIf="data.input_type=='whattodo'">
																	<h4 class="text-uppercase">{{data.symptom_name}}
																	</h4>
																	<h5 style="padding-left: 50px;"
																		[innerHTML]="data.question"></h5>
																	<hr
																		style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="panel-body text-center">
								<button *ngIf="moduleAccess=='Full'" [disabled]="showLoader" type="submit"
									id="btnSubmit" class="mr mb-sm btn btn-sm btn-success"><span
										style="margin-right: 5px;"><i *ngIf="showLoader" aria-hidden="true"
											class="fa fa-spinner fa-spin"></i><i *ngIf="!showLoader"
											class="fa fa-save"></i></span>Save</button>
								<button type="button" id="btnCancel" class="mr mb-sm btn btn-sm btn-default"
									[routerLink]="['/app/eventList']">Cancel</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="moduleAccess!='Full' && moduleAccess!='Readonly'">
		<div class="text-center">
			<h4>You don&apos;t have access to this section.</h4>
		</div>
	</div>
</section>